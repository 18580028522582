define("discourse/plugins/hosted-site/discourse/components/incoming-email", ["exports", "@ember/component", "discourse/lib/ajax", "discourse-common/lib/debounce", "discourse-common/lib/get-url", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _ajax, _debounce, _getUrl, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.identifier}}
    {{#conditional-loading-spinner condition=this.loading size="small"}}
      <label>
        {{input type="checkbox" checked=this.enabled}}
        {{html-safe this.acceptIncomingEmailText}}
      </label>
    {{/conditional-loading-spinner}}
  {{/if}}
  */
  {
    "id": "oOce/fC6",
    "block": "[[[41,[30,0,[\"identifier\"]],[[[6,[39,1],null,[[\"condition\",\"size\"],[[30,0,[\"loading\"]],\"small\"]],[[\"default\"],[[[[1,\"    \"],[10,\"label\"],[12],[1,\"\\n      \"],[1,[28,[35,3],null,[[\"type\",\"checked\"],[\"checkbox\",[30,0,[\"enabled\"]]]]]],[1,\"\\n      \"],[1,[28,[35,4],[[30,0,[\"acceptIncomingEmailText\"]]],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]]],[]],null]],[],false,[\"if\",\"conditional-loading-spinner\",\"label\",\"input\",\"html-safe\"]]",
    "moduleName": "discourse/plugins/hosted-site/discourse/components/incoming-email.hbs",
    "isStrictMode": false
  });
  class IncomingEmail extends _component.default {
    loading = true;
    _resetLoading() {
      this.set("loading", true);
    }
    static #_ = (() => dt7948.n(this.prototype, "_resetLoading", [(0, _decorators.observes)("identifier")]))();
    _updateEmail() {
      let emails = this.get("alternativeIncomingEmails").slice();
      if (this.get("enabled")) {
        emails.push(this.get("discoursemail"));
      }
      this.set("email", emails.join("|"));
    }
    static #_2 = (() => dt7948.n(this.prototype, "_updateEmail", [(0, _decorators.observes)("alternativeIncomingEmails", "enabled", "discoursemail")]))();
    acceptIncomingEmailText(email) {
      return `Accept incoming emails sent to: <a href='mailto:${email}'>${email}</a>`;
    }
    static #_3 = (() => dt7948.n(this.prototype, "acceptIncomingEmailText", [(0, _decorators.default)("discoursemail")]))();
    alternativeIncomingEmails(incomingEmails, discoursemail) {
      return incomingEmails.split("|").filter(email => /.+@.+\..+/.exec(email) && email !== discoursemail);
    }
    static #_4 = (() => dt7948.n(this.prototype, "alternativeIncomingEmails", [(0, _decorators.default)("incomingEmails", "discoursemail")]))();
    alternativeIncomingEmailsText(emails) {
      if (emails.length === 0) {
        return;
      }
      emails = emails.map(email => `<a href='mailto:${email}'>${email}</a>`).join(", ");
      return `Already accepting incoming emails sent to: ${emails}.`;
    }
    static #_5 = (() => dt7948.n(this.prototype, "alternativeIncomingEmailsText", [(0, _decorators.default)("alternativeIncomingEmails")]))();
    url(type, identifier) {
      return (0, _getUrl.default)(`/admin/incoming_emails/${type}/${identifier}.json`);
    }
    static #_6 = (() => dt7948.n(this.prototype, "url", [(0, _decorators.default)("type", "identifier")]))();
    _fetchIncomingEmailStatus() {
      (0, _debounce.default)(this, this._debouncedFetchIncomingEmailStatus, 100);
    }
    static #_7 = (() => dt7948.n(this.prototype, "_fetchIncomingEmailStatus", [(0, _decorators.observes)("url"), (0, _decorators.on)("didInsertElement")]))();
    async _debouncedFetchIncomingEmailStatus() {
      if (!this.identifier) {
        return;
      }
      try {
        const r = await (0, _ajax.ajax)(this.url);
        this.setProperties({
          discoursemail: r.discoursemail,
          incomingEmails: r.incoming_emails,
          enabled: r.enabled
        });
      } finally {
        this.set("loading", false);
      }
    }
  }
  _exports.default = IncomingEmail;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, IncomingEmail);
});