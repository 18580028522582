define("discourse/plugins/hosted-site/discourse/components/modal/admin-upgrade-plan", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "discourse/lib/ajax", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _ajax, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal class="hosted-upgrade-modal">
    <:body>
      {{#if @model.suggested_upgrade_message}}
        <section class="upgrade-plan-details">
          <p>
            {{@model.suggested_upgrade_message}}
          </p>
          {{#if this.isTierUpgrade}}
            <p>
              You’ll also be able to do more with Discourse!
            </p>
            <p>
              Learn more about the
              <a
                href={{this.pluginsUrl}}
                target="_blank"
                rel="noopener noreferrer"
              >plugins</a>
              and
              <a
                href={{this.featuresUrl}}
                target="_blank"
                rel="noopener noreferrer"
              >features</a>
              available on the
              {{@model.suggestedTier}}
              plan.
            </p>
          {{/if}}
        </section>
        <p>
          Do you want to proceed?
        </p>
      {{else}}
        <p>
          Are you sure you want to upgrade?
        </p>
      {{/if}}
    </:body>
  
    <:footer>
      <DButton
        @translatedLabel={{this.yesButtonLabel}}
        @action={{this.performUpgrade}}
        class="btn-primary"
      />
  
      <DButton @translatedLabel="Cancel" @action={{@closeModal}} />
    </:footer>
  </DModal>
  */
  {
    "id": "blyrkkCR",
    "block": "[[[8,[39,0],[[24,0,\"hosted-upgrade-modal\"]],null,[[\"body\",\"footer\"],[[[[1,\"\\n\"],[41,[30,1,[\"suggested_upgrade_message\"]],[[[1,\"      \"],[10,\"section\"],[14,0,\"upgrade-plan-details\"],[12],[1,\"\\n        \"],[10,2],[12],[1,\"\\n          \"],[1,[30,1,[\"suggested_upgrade_message\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"],[41,[30,0,[\"isTierUpgrade\"]],[[[1,\"          \"],[10,2],[12],[1,\"\\n            You’ll also be able to do more with Discourse!\\n          \"],[13],[1,\"\\n          \"],[10,2],[12],[1,\"\\n            Learn more about the\\n            \"],[10,3],[15,6,[30,0,[\"pluginsUrl\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"plugins\"],[13],[1,\"\\n            and\\n            \"],[10,3],[15,6,[30,0,[\"featuresUrl\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"features\"],[13],[1,\"\\n            available on the\\n            \"],[1,[30,1,[\"suggestedTier\"]]],[1,\"\\n            plan.\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n      \"],[10,2],[12],[1,\"\\n        Do you want to proceed?\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,2],[12],[1,\"\\n        Are you sure you want to upgrade?\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"]],[]],[[[1,\"\\n    \"],[8,[39,7],[[24,0,\"btn-primary\"]],[[\"@translatedLabel\",\"@action\"],[[30,0,[\"yesButtonLabel\"]],[30,0,[\"performUpgrade\"]]]],null],[1,\"\\n\\n    \"],[8,[39,7],null,[[\"@translatedLabel\",\"@action\"],[\"Cancel\",[30,2]]],null],[1,\"\\n  \"]],[]]]]]],[\"@model\",\"@closeModal\"],false,[\"d-modal\",\":body\",\"if\",\"section\",\"p\",\"a\",\":footer\",\"d-button\"]]",
    "moduleName": "discourse/plugins/hosted-site/discourse/components/modal/admin-upgrade-plan.hbs",
    "isStrictMode": false
  });
  class AdminUpgradePlan extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    get isTierUpgrade() {
      return this.args.model.currentTier !== this.args.model.suggestedTier;
    }
    get pluginsUrl() {
      return `https://www.discourse.org/plugins#${this.args.model.suggestedTier.toLowerCase()}`;
    }
    get featuresUrl() {
      return `https://www.discourse.org/pricing#compare-plans`;
    }
    get yesButtonLabel() {
      return `Upgrade plan!`;
    }
    async performUpgrade() {
      try {
        const data = await (0, _ajax.ajax)("/admin/hosted_plan.json", {
          type: "PUT",
          data: {
            plan_id: this.args.model.plan_id,
            add_on_ids: this.args.model.add_on_ids,
            context: this.args.model.context
          }
        });
        this.args.closeModal();
        if (data.success) {
          this.dialog.alert(data.message || "Your account has been successfully upgraded.");
        } else {
          this.dialog.alert(`Your upgrade could not be completed. ${data.message}`);
        }
        this.args.model.onComplete?.();
      } catch {
        this.args.closeModal();
        this.dialog.alert("There was an error submitting your upgrade request. Please send an email to team@discourse.org so we can look into it.");
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "performUpgrade", [_object.action]))();
  }
  _exports.default = AdminUpgradePlan;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminUpgradePlan);
});