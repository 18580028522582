define("discourse/plugins/hosted-site/discourse/components/modal/admin-contact-email", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/utilities", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _ajax, _utilities, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title="Change Contact Email Address"
    @closeModal={{@closeModal}}
    class="hosted-contact-email"
  >
    <:body>
      <p>
        Set the email address where we can reach you about your Discourse hosting
        service.
      </p>
      <p>
        <TextField
          @value={{this.contactEmail}}
          @placeholderKey="topic.invite_reply.email_placeholder"
          @id="hosted-contact-email-input"
          @autocorrect="off"
          @autocapitalize="off"
          @type="email"
        />
        <br />
        <InputTip @validation={{this.emailValidation}} />
      </p>
    </:body>
  
    <:footer>
      <DButton
        @translatedLabel="Save"
        @action={{this.save}}
        @disabled={{this.invalidEmail}}
        class="btn-primary"
      />
  
      <DButton
        @translatedLabel="Cancel"
        @action={{@closeModal}}
        class="btn-flat"
      />
    </:footer>
  </DModal>
  */
  {
    "id": "+Q3Xode1",
    "block": "[[[8,[39,0],[[24,0,\"hosted-contact-email\"]],[[\"@title\",\"@closeModal\"],[\"Change Contact Email Address\",[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,2],[12],[1,\"\\n      Set the email address where we can reach you about your Discourse hosting\\n      service.\\n    \"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@value\",\"@placeholderKey\",\"@id\",\"@autocorrect\",\"@autocapitalize\",\"@type\"],[[30,0,[\"contactEmail\"]],\"topic.invite_reply.email_placeholder\",\"hosted-contact-email-input\",\"off\",\"off\",\"email\"]],null],[1,\"\\n      \"],[10,\"br\"],[12],[13],[1,\"\\n      \"],[8,[39,5],null,[[\"@validation\"],[[30,0,[\"emailValidation\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,7],[[24,0,\"btn-primary\"]],[[\"@translatedLabel\",\"@action\",\"@disabled\"],[\"Save\",[30,0,[\"save\"]],[30,0,[\"invalidEmail\"]]]],null],[1,\"\\n\\n    \"],[8,[39,7],[[24,0,\"btn-flat\"]],[[\"@translatedLabel\",\"@action\"],[\"Cancel\",[30,1]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\":body\",\"p\",\"text-field\",\"br\",\"input-tip\",\":footer\",\"d-button\"]]",
    "moduleName": "discourse/plugins/hosted-site/discourse/components/modal/admin-contact-email.hbs",
    "isStrictMode": false
  });
  class AdminContactEmail extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "contactEmail", [_tracking.tracked]))();
    #contactEmail = (() => (dt7948.i(this, "contactEmail"), void 0))();
    get invalidEmail() {
      return !(0, _utilities.emailValid)(this.contactEmail);
    }
    get emailValidation() {
      if (this.invalidEmail && this.contactEmail) {
        return _object.default.create({
          failed: true,
          reason: _discourseI18n.default.t("user.email.invalid")
        });
      }
    }
    async save() {
      try {
        await (0, _ajax.ajax)("/admin/hosted_account/update_contact_email.json", {
          type: "PUT",
          data: {
            email: this.contactEmail
          }
        });
        this.args.closeModal();
        this.dialog.alert("Your contact email address has been updated.");
        this.args.model.onComplete();
      } catch {
        this.args.closeModal();
        this.dialog.alert("There was an error updating your contact email address. Please send an email to team@discourse.org so we can look into it and update your account info.");
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "save", [_object.action]))();
  }
  _exports.default = AdminContactEmail;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminContactEmail);
});